var __jsx = React.createElement;
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
  return {
    root: {
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    loading: {
      marginTop: theme.spacing(2)
    }
  };
});

var LoadingIndicator = function LoadingIndicator() {
  var classes = useStyles();
  return __jsx("div", {
    className: classes.root
  }, __jsx(CircularProgress, {
    size: 48
  }), __jsx(Typography, {
    className: classes.loading,
    variant: "subtitle1"
  }, "Just a sec..."));
};

export default LoadingIndicator;