import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  loading: {
    marginTop: theme.spacing(2),
  },
}))
const LoadingIndicator = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress size={48} />
      <Typography className={classes.loading} variant="subtitle1">
        Just a sec...
      </Typography>
    </div>
  )
}

export default LoadingIndicator
